import { Stack, Text, Button } from "@chakra-ui/react";
import { useServerGame } from "../../../../../hooks/serverGame";
import { Id } from "../../../../../convex/_generated/dataModel";
import { GameId } from "../../../../../convex/aiTown/ids";
import { usePaginatedQuery } from "convex/react";
import { api } from "../../../../../convex/_generated/api";

const TerminalBox = ({ worldId }: { worldId?: Id<"worlds"> }) => {
  const game = useServerGame(worldId);
  const playerDescriptions = game?.playerDescriptions;

  const {
    results: memories,
    status,
    loadMore,
  } = usePaginatedQuery(
    api.agent.memory.getWorldUIMemories,
    {
      worldId: worldId as Id<"worlds">,
    },
    { initialNumItems: 20 }
  );

  return (
    <Stack gap={6}>
      <Stack>
        <Stack align={"start"} gap={2}>
          {memories?.map((memory) => {
            const player = playerDescriptions?.get(
              memory.playerId as GameId<"players">
            );
            return (
              <Text key={memory._id} fontSize={"sm"}>
                <Text as="span" fontWeight="bold" textTransform="uppercase">
                  {player?.name}:{" "}
                </Text>
                {memory.description}
              </Text>
            );
          })}
        </Stack>
        {status === "CanLoadMore" && (
          <Button
            onClick={() => loadMore(20)}
            size="sm"
            sx={{
              color: "brand.primary700",
              bg: "brand.primary100",
              _hover: {
                bg: "brand.primary200",
                color: "brand.primary800",
              },
            }}
          >
            Load More
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default TerminalBox;
