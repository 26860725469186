import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  memo,
} from "react";
import { useRef } from "react";
import PixiGame from "./PixiGame";
import useResizeObserver from "./hooks/useResizeObserver";
import { useNavigate } from "react-router-dom";
import { Stage } from "@pixi/react";
import { ConvexProvider, useConvex, useQuery } from "convex/react";
import PlayerDetails from "./PlayerDetails";
import { api } from "../../convex/_generated/api";
import { useMutation } from "convex/react";
import { useWorldHeartbeat } from "../../hooks/useWorldHeartbeat";
import { useHistoricalTime } from "../../hooks/useHistoricalTime";
import { DebugTimeManager } from "./DebugTimeManager";
import { GameId, conversationId, playerId } from "../../convex/aiTown/ids";
import { useServerGame } from "../../hooks/serverGame";
import InteractButton from "./buttons/InteractButton";
import InvitePopup from "./InvitePopup";
import { Menu, ChevronsDown, ChevronsRight, Box } from "lucide-react";
import GodviewSidebar from "./GodviewSidebar";
import { motion, AnimatePresence } from "framer-motion";
import FreezeButton from "./FreezeButton";
import { Volume2, VolumeX, Home } from "lucide-react";
import SpotMemberDisplay from "./SpotMemberDisplay";
import * as amplitude from "@amplitude/analytics-browser";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/joy";
import { Id } from "../../convex/_generated/dataModel";
import { TownContext } from "../../contexts/TownContext";

import { useMediaQuery } from "usehooks-ts";
import DebugActions from "./DebugActions";

// Play AI Stuff
import RightView from "../AgentTownSidePanel/components/Home/RightView";
import AppProvider from "../AgentTownSidePanel/components/Providers/AppProvider";
import { EvmWalletProvider } from "../AgentTownSidePanel/components/Providers/EvmWalletProvider";
import Btn from "../AgentTownSidePanel/components/Buttons/Btn";
import { SolWalletProvider } from "../AgentTownSidePanel/contexts/sol.context";
import "../AgentTownSidePanel/sidepanel.css";

import Spotlight from "./Spotlight";

export const IS_LOCAL_ENV = process.env.NODE_ENV === "development";
export const SHOW_DEBUG_UI = false;

// Adjust this value based on your bottom bar height

interface GameProps {
  worldStatus: {
    worldId: Id<"worlds">;
    engineId: Id<"engines">;
    status: "stoppedByDeveloper" | "inactive" | "running";
  };
  townName: string;
}

interface MobileBottomBarProps {
  worldStatus: GameProps["worldStatus"];
  isSpectator: boolean;
  godviewOpen: boolean;
  setGodviewOpen: (open: boolean) => void;
  handleHomeClick: () => void;
  mapAudioUrl?: string;
  user: boolean;
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  playerId: GameId<"players">;
  game: ReturnType<typeof useServerGame>;
  cryptoSpot?: boolean;
  setSelectedElement: (
    element: { kind: "player"; id: GameId<"players"> } | undefined
  ) => void;
  onPlayerClick: (playerId: GameId<"players">) => void;
}

// Extract audio player into separate component
const AudioPlayer = memo(
  ({
    cryptoSpot,
    mapAudioUrl,
  }: {
    cryptoSpot?: boolean;
    mapAudioUrl?: string;
  }) => {
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const toggleAudio = useCallback(() => {
      if (audioRef.current) {
        if (isAudioPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play();
        }
        setIsAudioPlaying(!isAudioPlaying);
      }
    }, [isAudioPlaying]);

    useEffect(() => {
      if (mapAudioUrl) {
        audioRef.current = new Audio(mapAudioUrl);
        audioRef.current.loop = true;
      }
      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
      };
    }, [mapAudioUrl]);

    return (
      <>
        {cryptoSpot ? (
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              onClick={toggleAudio}
              startDecorator={
                isAudioPlaying ? (
                  <Volume2 size={20} strokeWidth={1.5} />
                ) : (
                  <VolumeX size={20} strokeWidth={1.5} />
                )
              }
              className="!bg-fourwall-orange hover:!bg-orange-500 !text-gray-200 !font-main !rounded-full !border-2 !border-solid !border-black"
            >
              Music
            </Button>
          </motion.div>
        ) : (
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            {isAudioPlaying ? (
              <Volume2
                className="cursor-pointer !text-gray-200"
                size={32}
                strokeWidth={1.5}
                onClick={toggleAudio}
              />
            ) : (
              <VolumeX
                className="cursor-pointer !text-gray-200"
                size={32}
                strokeWidth={1.5}
                onClick={toggleAudio}
              />
            )}
          </motion.div>
        )}
      </>
    );
  }
);

const CryptoControls = memo(
  ({
    isSpectator,
    debugKickHuman,
    cryptoSpot,
    worldStatus,
    mapAudioUrl,
  }: {
    isSpectator: boolean;
    debugKickHuman: () => void;
    cryptoSpot: boolean;
    worldStatus: GameProps["worldStatus"];
    mapAudioUrl: string | undefined;
  }) => {
    const isMobile = window.innerWidth < 768;
    return (
      <div
        className={`fixed flex flex-row items-center bottom-4 left-4 z-10 p-2 rounded-lg text-white gap-4`}
      >
        {!isSpectator && (
          <>
            <Button onClick={debugKickHuman} variant="outlined">
              Kick Human
            </Button>
            <FreezeButton
              cryptoSpot={cryptoSpot}
              worldStatus={worldStatus}
              size="small"
            />
          </>
        )}
        <>
          <AudioPlayer cryptoSpot={cryptoSpot} mapAudioUrl={mapAudioUrl} />
        </>
      </div>
    );
  }
);
// Extract mobile controls into separate component
const MobileBottomBar = memo(
  ({
    worldStatus,
    isSpectator,
    godviewOpen,
    setGodviewOpen,
    handleHomeClick,
    mapAudioUrl,
    game,
    cryptoSpot,

    ...spotMemberProps
  }: MobileBottomBarProps) => (
    <div className="fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center z-50 bg-neutral-900 rounded-t-lg overflow-hidden border-t border-orange-700">
      {!cryptoSpot && (
        <motion.div
          whileTap={{ scale: 0.9 }}
          className="transition-all duration-300 rounded-lg p-1 ml-2"
        >
          {!godviewOpen ? (
            <Menu
              color="white"
              size={22}
              onClick={() => setGodviewOpen(true)}
              className="!cursor-pointer !z-40 hover:text-fourwall-orange"
            />
          ) : (
            <ChevronsDown
              color="white"
              size={22}
              onClick={() => setGodviewOpen(false)}
              className="!cursor-pointer !z-40"
            />
          )}
        </motion.div>
      )}

      <SpotMemberDisplay {...spotMemberProps} game={game!} />

      <div className="flex flex-row items-center gap-3 mr-2">
        {!isSpectator && (
          <FreezeButton worldStatus={worldStatus} size="small" />
        )}
        <AudioPlayer mapAudioUrl={mapAudioUrl} />
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Home
            onClick={handleHomeClick}
            className="cursor-pointer text-gray-400"
            size={30}
            strokeWidth={1.5}
          />
        </motion.div>
      </div>
    </div>
  )
);

export default function Game({ worldStatus, townName }: GameProps) {
  const convex = useConvex();
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const isMobile = window.innerWidth < 768;

  const [spotlightOpen, setSpotlightOpen] = useState(false);

  const townContext = useContext(TownContext);
  if (!townContext) {
    throw new Error("TownContext not found");
  }
  const { isSpectator, creator, cryptoSpot, town_id } = townContext;
  const [isRightViewOpen, setIsRightViewOpen] = useState(
    cryptoSpot && !isMobile
  );
  const [godviewOpen, setGodviewOpen] = useState(!isMobile && !cryptoSpot);
  const [focusedPlayerId, setFocusedPlayerId] = useState<GameId<"players">>();
  const [selectedElement, setSelectedElement] = useState<{
    kind: "player";
    id: GameId<"players">;
  }>();

  const MOBILE_BAR_HEIGHT = cryptoSpot ? 0 : 64; // Adjust this value based on your bottom bar height

  const worldId = worldStatus?.worldId;
  const engineId = worldStatus?.engineId;
  const game = useServerGame(worldId as Id<"worlds">);
  const players = [...(game?.world.players.values() || [])];

  const [gameWrapperRef, { width, height }] = useResizeObserver();
  const [size, setSize] = useState({
    width: window.innerWidth - (godviewOpen ? 300 : 0),
    height: window.innerHeight - (isMobile ? MOBILE_BAR_HEIGHT : 0),
  });
  const frozen =
    worldStatus?.status === "stoppedByDeveloper" ||
    worldStatus?.status === "inactive";
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width:
          window.innerWidth -
          (godviewOpen ? 300 : 0) -
          (isRightViewOpen ? 400 : 0),
        height: window.innerHeight - (isMobile ? MOBILE_BAR_HEIGHT : 0),
      });
    };
    handleResize();
  }, [godviewOpen, isMobile, isRightViewOpen]);

  useWorldHeartbeat();
  const worldState = useQuery(
    api.world.worldState,
    worldId ? { worldId: worldId as Id<"worlds"> } : "skip"
  );
  const { historicalTime, timeManager } = useHistoricalTime(worldState?.engine);
  const scrollViewRef = useRef<HTMLDivElement>(null);

  // Memoize handlers
  const handlePlayerFocus = useCallback(
    (playerId: GameId<"players">) => {
      amplitude.track("Spot Player Clicked", {
        town_id: worldId,
        player_id: playerId,
        origin: "sidebar",
      });
      setSelectedElement({ kind: "player", id: playerId });
      setFocusedPlayerId(playerId);
    },
    [worldId]
  );

  const handleHomeClick = useCallback(() => {
    setSelectedElement(undefined);
    navigate(!isSpectator ? "/spot" : "/");
  }, [isSpectator, navigate]);

  // Memoize size calculation
  const gameSize = useMemo(
    () => ({
      width:
        window.innerWidth -
        (godviewOpen ? 300 : 0) -
        (isRightViewOpen ? 400 : 0),
      height: window.innerHeight - (isMobile ? MOBILE_BAR_HEIGHT : 0),
    }),
    [godviewOpen, isMobile, isRightViewOpen]
  );

  // Add this query to get the map audio URL
  const map = useQuery(api.world.getMap, {
    worldId: worldId as Id<"worlds">,
  });

  const mapAudioUrl = map?.mapAudioUrl
    ? `/music/${map.mapAudioUrl}`
    : undefined;

  const leave = useMutation(api.world.leaveWorld);
  const debugKickHuman = useCallback(() => {
    leave({ worldId });
  }, [leave, worldId]);
  // Early return for invalid state
  if (!worldId || !engineId || !game) return null;

  return (
    <div
      className="absolute inset-0 flex flex-row transition-all duration-300 min-h-screen overflow-hidden"
      style={{
        zIndex: 0,
        width: "100%",
      }}
    >
      {/* Only show Debug Actions in local environment */}

      {/* Debug UI */}
      {/* {IS_LOCAL_ENV && (
        <DebugActions
          worldId={worldId as Id<"worlds">}
          playerId="p:0" /// @viswajit this should be dynamic
          otherPlayerId="p:8" /// @viswajit this should be dynamic or blank string
          agentId="a:1" /// @viswajit this should be dynamic -- agentid for playerId
          type="" // @viswajit should be one of these influenceAttack || influenceDance || influenceIncept || influenceHurt
          inceptMemory="You are going to die soon." /// @viswajit this should be empty string or memory to be incepted
        />
      )} */}
      {SHOW_DEBUG_UI && (
        <DebugTimeManager timeManager={timeManager} width={200} height={100} />
      )}

      {/* Invite Popup */}
      {!isSpectator && (
        <InvitePopup
          worldId={worldId as Id<"worlds">}
          engineId={engineId as Id<"engines">}
          game={game}
        />
      )}
      {/* Redeploy*/}
      {/* Add desktop toggle button */}
      {!cryptoSpot && <InteractButton worldStatus={worldStatus} />}
      {/* Godview Sidebar */}

      <GodviewSidebar
        worldId={worldId as Id<"worlds">}
        engineId={engineId as Id<"engines">}
        setSelectedElement={setSelectedElement}
        onPlayerClick={handlePlayerFocus}
        open={godviewOpen}
        setOpen={setGodviewOpen}
        game={game}
        creator={creator}
      />

      {/* Main Game Area */}
      <div className="flex-1 relative">
        <div
          className="relative bg-brown-900 w-full h-full"
          ref={gameWrapperRef}
        >
          <div
            className={`relative w-full h-full ${frozen ? "grayscale" : ""}`}
          >
            <Stage
              width={
                width ||
                window.innerWidth -
                  (godviewOpen ? 300 : 0) -
                  (isRightViewOpen ? 400 : 0)
              }
              height={
                height ||
                window.innerHeight - (isMobile ? MOBILE_BAR_HEIGHT : 0)
              }
            >
              <ConvexProvider client={convex}>
                <PixiGame
                  game={game}
                  worldId={worldId as Id<"worlds">}
                  engineId={engineId as Id<"engines">}
                  width={size.width}
                  height={size.height}
                  historicalTime={historicalTime}
                  setSelectedElement={setSelectedElement}
                  focusedPlayerId={focusedPlayerId}
                  worldStatus={worldStatus}
                />
              </ConvexProvider>
            </Stage>
            {frozen && (
              <div className="absolute inset-0 bg-gray-900/70 z-5 flex flex-col items-center justify-center">
                <div className="p-6 rounded-lg">
                  <p className="text-white font-main text-center">
                    The spot is currently paused by the creator
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {!isAuthenticated && isSpectator && !cryptoSpot && !isRightViewOpen ? (
        <motion.div
          className={`fixed ${isMobile ? "right-20 bottom-20" : "right-10 bottom-10"} font-main text-sm p-3 rounded-lg bg-gradient-to-br from-orange-500 via-orange-600 to-orange-700 hover:from-orange-400 hover:via-orange-500 hover:to-orange-600 text-gray-50 hover:bg-gradient-to-br cursor-pointer shadow-lg shadow-black/30 hover:shadow-xl hover:shadow-black/40 transition-all`}
          onClick={() =>
            loginWithRedirect({
              appState: { returnTo: "/spot" },
            })
          }
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <p>Sign in to 4Wall AI to create your own spot!</p>
        </motion.div>
      ) : null}

      {/* Toggle button */}
      {cryptoSpot && (
        <>
          {!isRightViewOpen && (
            <motion.button
              className="fixed right-4 top-4 z-50 p-2 rounded-lg bg-red-400 text-white hover:bg-red-500/80 "
              onClick={() => setIsRightViewOpen(!isRightViewOpen)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Box size={24} />
            </motion.button>
          )}

          <AnimatePresence>
            <motion.div
              initial={{ width: 0 }}
              animate={{
                width: isRightViewOpen
                  ? window.innerWidth < 768
                    ? "100vw"
                    : 400
                  : 0,
              }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              className="fixed right-0 top-0 h-full overflow-hidden z-50"
            >
              <EvmWalletProvider>
                <AppProvider>
                  {/* <SolWalletProvider> */}
                  <RightView
                    rightView={isRightViewOpen}
                    setRightView={setIsRightViewOpen}
                  />
                  {/* </SolWalletProvider> */}
                </AppProvider>
              </EvmWalletProvider>
            </motion.div>
          </AnimatePresence>
        </>
      )}

      {/* Player Details Sidebar */}
      {selectedElement?.id && (
        <PlayerDetails
          {...{
            worldId: worldId as Id<"worlds">,
            engineId: engineId as Id<"engines">,
            game,
            playerId: selectedElement.id,
            setSelectedElement,
            scrollViewRef,
          }}
        />
      )}

      <Spotlight
        worldId={worldId as Id<"worlds">}
        open={spotlightOpen}
        setOpen={setSpotlightOpen}
      />
      {isMobile ? (
        !cryptoSpot ? (
          <MobileBottomBar
            worldStatus={worldStatus}
            isSpectator={isSpectator}
            godviewOpen={godviewOpen}
            setGodviewOpen={setGodviewOpen}
            handleHomeClick={handleHomeClick}
            mapAudioUrl={mapAudioUrl}
            user={true}
            worldId={worldId as Id<"worlds">}
            engineId={engineId as Id<"engines">}
            playerId={players.find((p) => p.human)?.id as GameId<"players">}
            game={game}
            cryptoSpot={cryptoSpot}
            setSelectedElement={setSelectedElement}
            onPlayerClick={handlePlayerFocus}
          />
        ) : (
          !isRightViewOpen && (
            <CryptoControls
              isSpectator={isSpectator}
              debugKickHuman={debugKickHuman}
              cryptoSpot={cryptoSpot}
              worldStatus={worldStatus}
              mapAudioUrl={mapAudioUrl}
            />
          )
        )
      ) : cryptoSpot ? (
        <CryptoControls
          isSpectator={isSpectator}
          debugKickHuman={debugKickHuman}
          cryptoSpot={cryptoSpot}
          worldStatus={worldStatus}
          mapAudioUrl={mapAudioUrl}
        />
      ) : null}
      {!godviewOpen && !isMobile && !cryptoSpot && (
        <div
          className="fixed top-3 left-3 transition-all duration-300 rounded-lg p-1"
          style={{
            background: "linear-gradient(45deg, #FF6B00, #FF6B00, #FFB84D)",
            transition: "background 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.background =
              "linear-gradient(45deg, #FF6B00, #FF6B00, #FFB84D)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.background =
              "linear-gradient(45deg, #FF4500, #FF4500, #F4AA20)";
          }}
        >
          <Menu
            color="white"
            size={22}
            onClick={() => setGodviewOpen(true)}
            className="!cursor-pointer !z-40"
          />
        </div>
      )}
    </div>
  );
}
