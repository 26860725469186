import React from "react";
import {
  Modal,
  ModalDialog,
  Typography,
  DialogTitle,
  DialogContent,
  ModalClose,
  CircularProgress,
  Tooltip,
} from "@mui/joy";
import { ChevronDown, Play, Pause, ScanEye } from "lucide-react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useContext, useCallback, useMemo } from "react";
import { TownContext } from "../../contexts/TownContext";
import { api } from "../../convex/_generated/api";
import { useQuery, usePaginatedQuery } from "convex/react";
import { Id } from "../../convex/_generated/dataModel";
import { useMediaQuery } from "usehooks-ts";
import { useAudio } from "../../contexts/AudioContext";

interface SpotlightProps {
  worldId: Id<"worlds">;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface SpotlightData {
  _id: string;
  spotlightId?: string;
  _creationTime: number;
  imageUrl: string;
  uiText: {
    title: string;
    article: string;
  };
  worldId: Id<"worlds">;
}

// Add this helper function near your other utility functions
const formatTime = (timestamp: number) => {
  return new Date(timestamp).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

// Add this test function near the top of the file
const createTestSpotlight = () => ({
  _id: `test-${Date.now()}`,
  _creationTime: Date.now(),
  imageUrl: "https://picsum.photos/400/400", // Random test image
  uiText: {
    title: `Test Spotlight ${new Date().toLocaleTimeString()}`,
    article:
      "This is a test spotlight generated for development purposes. It includes some sample text to demonstrate the auto-play functionality.",
  },
});

// Add this helper function to truncate text
const truncateText = (text: string, limit: number) => {
  if (text.length <= limit) return text;
  return text.slice(0, limit) + "...";
};

// Move these to be standalone components outside the main Spotlight component
const SpotlightHeader = () => {
  return (
    <>
      <Typography className="!text-fourwall-orange !font-bold !text-5xl !text-center !py-4 !font-main">
        SpotLight
      </Typography>
    </>
  );
};

// Update SpotlightContent props interface
interface SpotlightContentProps {
  sortedSpotlight: SpotlightData[];
  expandedSpotlights: Set<string>;
  toggleExpanded: (spotlightId: string, e: React.MouseEvent) => void;
  worldId: Id<"worlds">;
}

const ITEMS_PER_PAGE = 20;

const SpotlightContent = React.memo(
  ({
    sortedSpotlight,
    expandedSpotlights,
    toggleExpanded,
    worldId,
  }: SpotlightContentProps) => {
    const [displayCount, setDisplayCount] = React.useState(ITEMS_PER_PAGE);
    const { currentAudio, playAudio } = useAudio();

    // Get only the spotlights we want to display
    const displayedSpotlights = useMemo(() => {
      return sortedSpotlight.slice(0, displayCount);
    }, [sortedSpotlight, displayCount]);

    const hasMore = displayCount < sortedSpotlight.length;

    const loadMore = useCallback(() => {
      setDisplayCount((prev) =>
        Math.min(prev + ITEMS_PER_PAGE, sortedSpotlight.length)
      );
    }, [sortedSpotlight.length]);

    // Handle audio playback
    const handlePlayAudio = useCallback(
      (spotlightId: string, audioUrl: string) => {
        console.log("Playing audio:", { spotlightId, audioUrl });
        playAudio(spotlightId, audioUrl, "spotlight");
      },
      [playAudio]
    );

    return (
      <div className="space-y-4 pb-4">
        {sortedSpotlight.length === 0 ? (
          <div className="flex justify-center items-center my-40 py-4">
            <Typography className="!text-gray-400 !text-xl">
              If something of importance happens, you'll see it here.
            </Typography>
          </div>
        ) : (
          <>
            {displayedSpotlights.map((spotlight, index) => (
              <React.Fragment key={spotlight._id}>
                {/* Mobile Layout */}
                <div className="md:hidden bg-neutral-900 items-center overflow-hidden flex flex-col border border-neutral-800 rounded-lg p-2">
                  <img
                    loading="lazy"
                    src={spotlight.imageUrl}
                    alt="Event"
                    className="w-96 h-auto object-cover rounded-lg shadow-lg shadow-white/10"
                  />
                  <div className="flex flex-col space-y-2 mt-2">
                    <div className="flex items-center justify-between w-full">
                      <Typography
                        className="!text-gray-100 !font-main !text-sm font-bold break-words"
                        sx={{ wordBreak: "break-word", flex: 1 }}
                      >
                        {spotlight.uiText.title}
                      </Typography>
                      <Typography className="!text-gray-400 !text-sm ml-2">
                        {formatTime(spotlight._creationTime)}
                      </Typography>
                    </div>
                    <div className="flex items-center gap-2">
                      <Typography
                        className="!text-gray-300 !font-chat !text-xs break-words"
                        sx={{ wordBreak: "break-word" }}
                      >
                        {expandedSpotlights.has(spotlight._id)
                          ? spotlight.uiText.article
                          : truncateText(spotlight.uiText.article, 250)}
                      </Typography>
                      {spotlight.uiText.article.length > 250 && (
                        <Typography
                          onClick={(e) => toggleExpanded(spotlight._id, e)}
                          className="!text-fourwall-orange hover:!text-fourwall-orange/80 !text-xs !cursor-pointer"
                        >
                          {expandedSpotlights.has(spotlight._id)
                            ? "See less"
                            : "See more"}
                        </Typography>
                      )}
                    </div>
                    <div className="flex justify-end">
                      <AudioToolbar
                        spotlight={spotlight}
                        currentAudio={currentAudio}
                        onPlayAudio={handlePlayAudio}
                        worldId={worldId}
                      />
                    </div>
                  </div>
                </div>

                {/* Desktop Layout */}
                <div
                  className={`hidden md:flex bg-neutral-900 overflow-hidden gap-4 border border-neutral-800 rounded-lg p-2 ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"}`}
                >
                  <img
                    loading="lazy"
                    src={spotlight.imageUrl}
                    alt="Event"
                    className="w-24 h-24 md:w-72 md:h-72 object-cover flex-shrink-0 rounded-lg shadow-lg shadow-white/10"
                  />
                  <div className="flex flex-col justify-between space-y-2">
                    <div className="flex items-center justify-between w-full mb-2">
                      <Typography
                        className="!text-gray-100 !font-main !text-lg font-bold break-words"
                        sx={{ wordBreak: "break-word", flex: 1 }}
                      >
                        {spotlight.uiText.title}
                      </Typography>
                      <Typography className="!text-gray-400 !text-sm ml-2">
                        {formatTime(spotlight._creationTime)}
                      </Typography>
                    </div>
                    <div className="flex flex-col gap-1">
                      <Typography
                        className="!text-gray-300 !font-chat !text-md break-words"
                        sx={{ wordBreak: "break-word" }}
                      >
                        {spotlight.uiText.article}
                      </Typography>
                    </div>
                    <div className="flex justify-end mt-auto">
                      <AudioToolbar
                        spotlight={spotlight}
                        currentAudio={currentAudio}
                        onPlayAudio={handlePlayAudio}
                        worldId={worldId}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}

            {hasMore && (
              <div className="flex justify-center py-4">
                <button
                  onClick={loadMore}
                  className="bg-fourwall-orange text-white px-4 py-2 rounded-full hover:bg-fourwall-orange/80 transition-colors"
                >
                  Load More
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

// Update AudioToolbarProps
interface AudioToolbarProps {
  spotlight: SpotlightData;
  currentAudio: {
    type: "conversation" | "spotlight" | null;
    messageId?: string;
  } | null;
  onPlayAudio: (spotlightId: string, audioUrl: string) => void;
  worldId: Id<"worlds">;
}

const AudioToolbar = ({
  spotlight,
  currentAudio,
  onPlayAudio,
}: AudioToolbarProps) => {
  const { stopAudio } = useAudio();

  const isPlaying =
    currentAudio?.type === "spotlight" &&
    currentAudio?.messageId === spotlight.spotlightId;

  console.log("AudioToolbar render:", {
    currentAudio,
    spotlightId: spotlight.spotlightId,
    isPlaying,
  });

  const handleAudioClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!spotlight.spotlightId) return;

    const audioUrl = `${process.env.REACT_APP_ASSETS_BUCKET}spots/${spotlight.worldId}/${spotlight.spotlightId}/audio.mp3`;

    if (isPlaying) {
      console.log("Stopping audio:", spotlight.spotlightId);
      stopAudio("spotlight");
    } else {
      console.log("Playing audio:", spotlight.spotlightId);
      onPlayAudio(spotlight.spotlightId, audioUrl);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={handleAudioClick}
        className="text-white hover:text-fourwall-orange p-2 cursor-pointer"
      >
        {isPlaying ? <Pause size={16} /> : <Play size={16} />}
      </button>
    </div>
  );
};

export default function Spotlight({ worldId, open, setOpen }: SpotlightProps) {
  const { currentAudio, playAudio, stopAudio } = useAudio();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    results: spotlightResults,
    status,
    loadMore,
  } = usePaginatedQuery(
    api.world.getSpotLight,
    {
      worldId,
    },
    { initialNumItems: 20 }
  );

  const townContext = useContext(TownContext);
  if (!townContext) {
    throw new Error("TownContext not found");
  }
  const { cryptoSpot } = townContext;

  const [expandedSpotlights, setExpandedSpotlights] = React.useState<
    Set<string>
  >(new Set());

  const toggleExpanded = useCallback(
    (spotlightId: string, e: React.MouseEvent) => {
      e.stopPropagation();
      setExpandedSpotlights((prev) => {
        const next = new Set(prev);
        if (next.has(spotlightId)) {
          next.delete(spotlightId);
        } else {
          next.add(spotlightId);
        }
        return next;
      });
    },
    []
  );

  const sortedSpotlight = useMemo((): SpotlightData[] => {
    if (!spotlightResults) return [];
    return [...spotlightResults].sort(
      (a, b) => b._creationTime - a._creationTime
    );
  }, [spotlightResults]);

  const handlePlayLatestSpotlight = useCallback(() => {
    if (!sortedSpotlight?.length) return;

    const latestSpotlight = sortedSpotlight[0];
    const audioUrl = `${process.env.REACT_APP_ASSETS_BUCKET}spots/${worldId}/${latestSpotlight.spotlightId}/audio.mp3`;

    if (!latestSpotlight.spotlightId) {
      toast.error("No spotlight ID found");
      return;
    }

    if (
      currentAudio?.type === "spotlight" &&
      currentAudio?.messageId === latestSpotlight.spotlightId
    ) {
      stopAudio("spotlight");
    } else {
      playAudio(latestSpotlight.spotlightId, audioUrl, "spotlight");

      toast.info(
        <div className="flex flex-col">
          <span className="font-bold">Playing Latest Spotlight</span>
          <span className="text-sm">{latestSpotlight.uiText.title}</span>
        </div>,
        {
          position: "bottom-right",
          autoClose: 3000,
        }
      );
    }
  }, [sortedSpotlight, currentAudio, playAudio, stopAudio, worldId]);

  const previousSpotlightRef = React.useRef<string | null>(null);

  React.useEffect(() => {
    if (sortedSpotlight.length > 0) {
      const latestSpotlight = sortedSpotlight[0];

      // Only show toast if this is a new spotlight
      if (previousSpotlightRef.current !== latestSpotlight._id) {
        previousSpotlightRef.current = latestSpotlight._id;

        // Don't show toast on initial load
        if (previousSpotlightRef.current !== null) {
          toast.info(
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setOpen(true)}
            >
              <img
                src={latestSpotlight.imageUrl}
                alt="Spotlight"
                className="w-12 h-12 object-cover rounded"
              />
              <div className="flex flex-col">
                <span className="font-bold">New Spotlight</span>
                <span className="text-sm">{latestSpotlight.uiText.title}</span>
              </div>
            </div>,
            {
              position: "bottom-right",
              autoClose: 5000,
              onClick: () => setOpen(true),
            }
          );
        }
      }
    }
  }, [sortedSpotlight, setOpen]);

  // Reset pagination when modal is opened
  React.useEffect(() => {
    if (open) {
      const contentComponent = document.querySelector(".spotlight-content");
      if (contentComponent) {
        contentComponent.scrollTop = 0;
      }
    }
  }, [open]);

  return (
    <>
      <div
        className={`absolute border-2 border-solid border-black bg-fourwall-orange p-1 !rounded-full flex gap-4 mx-2 px-4 ${cryptoSpot && !isMobile ? "top-3 left-3" : "left-1/2 -translate-x-1/2"}`}
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="!text-white !cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <ScanEye size={24} />
        </motion.button>

        <Tooltip
          title={
            currentAudio?.type === "spotlight"
              ? "Stop playing"
              : "Play latest Spotlight"
          }
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="!text-white !cursor-pointer"
            onClick={handlePlayLatestSpotlight}
          >
            {currentAudio?.type === "spotlight" ? (
              <Pause size={24} />
            ) : (
              <Play size={24} />
            )}
          </motion.button>
        </Tooltip>
      </div>

      {isMobile ? (
        open && (
          <div
            className={`fixed inset-0 ${!cryptoSpot ? "bottom-[64px]" : "bottom-0"} bg-neutral-900 z-50 flex flex-col`}
          >
            <button
              onClick={() => setOpen(false)}
              className="absolute top-4 right-4 text-white"
            >
              <ChevronDown size={24} />
            </button>
            <div className="flex flex-col h-full overflow-hidden">
              <SpotlightHeader />
              <div className="flex-1 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:transparent [&::-webkit-scrollbar-thumb]:bg-fourwall-orange [&::-webkit-scrollbar-thumb]:rounded-full">
                <SpotlightContent
                  sortedSpotlight={sortedSpotlight}
                  expandedSpotlights={expandedSpotlights}
                  toggleExpanded={toggleExpanded}
                  worldId={worldId}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          className={`!mx-auto ${!cryptoSpot ? "sm:!ml-4 md:!ml-8 lg:!ml-16 xl:!ml-40" : ""}`}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          <ModalDialog className="max-w-[600px] w-[60%] !h-[600px] !bg-neutral-900 !border-neutral-800 !rounded-3xl mt-8 p-0 overflow-hidden relative">
            <ModalClose onClick={() => setOpen(false)} />
            <DialogTitle className="!rounded-t-3xl !flex flex-col items-center !min-h-fit flex-shrink-0">
              <SpotlightHeader />
            </DialogTitle>
            <DialogContent className="!bg-neutral-900 p-4 overflow-y-auto overscroll-contain flex-grow [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:transparent [&::-webkit-scrollbar-thumb]:bg-fourwall-orange [&::-webkit-scrollbar-thumb]:rounded-full">
              <SpotlightContent
                sortedSpotlight={sortedSpotlight}
                expandedSpotlights={expandedSpotlights}
                toggleExpanded={toggleExpanded}
                worldId={worldId}
              />
            </DialogContent>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
}
